<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-11-01 14:56:51
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-03 13:56:12
 * @FilePath: \sipingnongzhuan2\src\views\aqbw\index.vue
-->
<template>
  <div>
    <List>
    </List>
  </div>
</template>
<script>
import List from '../../components/list/index.vue'
// import List from '../../components/list.vue'
export default {
  components: { List },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style>
</style>